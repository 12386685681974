<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        {{ instrument.name }}
      </VToolbarTitle>

      <VSpacer />

      <VTooltip
        v-if="$route.name !== Names.R_COMPETENCY_MATRIX_INSTRUMENT"
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            exact
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_COMPETENCY_MATRIX_INSTRUMENT, params : { instrumentId : instrument.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            exact
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_COMPETENCY_MATRIX_INSTRUMENT_EDIT, params : { instrumentId : instrument.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ instrument }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
export default {
  name: 'InstrumentCard',

  inject: ['Names'],

  props: {
    instrument: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
