<template>
  <TTView>
    <VRow>
      <VCol>
        <InstrumentCard :instrument="instrument" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import InstrumentCard from '../../components/competency-matrix/instruments/InstrumentCard.vue';

export default {
  name: 'Instrument',

  components: {
    InstrumentCard,
  },

  data() {
    return {
      loading: false,
      instrument: {},
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { instrumentId: id } = this.$route.params;
        const data = { id };

        const { instrument } = await this.$di.api.CompetencyMatrix.InstrumentsGet({ data });

        this.instrument = instrument;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
